body {
  background-color: rgb(245, 245, 245);
}

.ui-toast-message-content {
  font-family: "Roboto", "Trebuchet MS", Arial, Helvetica, sans-serif;
  background: #2196f3;
  color: #ffffff;
}

body .ui-toast .ui-toast-message.ui-toast-message-info .ui-toast-close-icon {
  color: #ffffff;
}

.mat-select-panel {
  background: var(--color-lightest);
  max-width: fit-content !important;
}

.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);
}

.mat-elevation-z0 {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, .2), 0 0 0 0 rgba(0, 0, 0, .14), 0 0 0 0 rgba(0, 0, 0, .12)
}

.mat-elevation-z1 {
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12)
}

.mat-elevation-z2 {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12)
}

.mat-elevation-z3 {
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, .2), 0 3px 4px 0 rgba(0, 0, 0, .14), 0 1px 8px 0 rgba(0, 0, 0, .12)
}

.mat-elevation-z4 {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12)
}

.mat-elevation-z5 {
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 5px 8px 0 rgba(0, 0, 0, .14), 0 1px 14px 0 rgba(0, 0, 0, .12)
}

.mat-elevation-z6 {
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12)
}

.mat-elevation-z7 {
  box-shadow: 0 4px 5px -2px rgba(0, 0, 0, .2), 0 7px 10px 1px rgba(0, 0, 0, .14), 0 2px 16px 1px rgba(0, 0, 0, .12)
}

.mat-elevation-z8 {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12)
}

.mat-elevation-z9 {
  box-shadow: 0 5px 6px -3px rgba(0, 0, 0, .2), 0 9px 12px 1px rgba(0, 0, 0, .14), 0 3px 16px 2px rgba(0, 0, 0, .12)
}

.mat-elevation-z10 {
  box-shadow: 0 6px 6px -3px rgba(0, 0, 0, .2), 0 10px 14px 1px rgba(0, 0, 0, .14), 0 4px 18px 3px rgba(0, 0, 0, .12)
}

.mat-elevation-z11 {
  box-shadow: 0 6px 7px -4px rgba(0, 0, 0, .2), 0 11px 15px 1px rgba(0, 0, 0, .14), 0 4px 20px 3px rgba(0, 0, 0, .12)
}

.mat-elevation-z12 {
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, .2), 0 12px 17px 2px rgba(0, 0, 0, .14), 0 5px 22px 4px rgba(0, 0, 0, .12)
}

.mat-elevation-z13 {
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, .2), 0 13px 19px 2px rgba(0, 0, 0, .14), 0 5px 24px 4px rgba(0, 0, 0, .12)
}

.mat-elevation-z14 {
  box-shadow: 0 7px 9px -4px rgba(0, 0, 0, .2), 0 14px 21px 2px rgba(0, 0, 0, .14), 0 5px 26px 4px rgba(0, 0, 0, .12)
}

.mat-elevation-z15 {
  box-shadow: 0 8px 9px -5px rgba(0, 0, 0, .2), 0 15px 22px 2px rgba(0, 0, 0, .14), 0 6px 28px 5px rgba(0, 0, 0, .12)
}

.mat-elevation-z16 {
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, .2), 0 16px 24px 2px rgba(0, 0, 0, .14), 0 6px 30px 5px rgba(0, 0, 0, .12)
}

.mat-elevation-z17 {
  box-shadow: 0 8px 11px -5px rgba(0, 0, 0, .2), 0 17px 26px 2px rgba(0, 0, 0, .14), 0 6px 32px 5px rgba(0, 0, 0, .12)
}

.mat-elevation-z18 {
  box-shadow: 0 9px 11px -5px rgba(0, 0, 0, .2), 0 18px 28px 2px rgba(0, 0, 0, .14), 0 7px 34px 6px rgba(0, 0, 0, .12)
}

.mat-elevation-z19 {
  box-shadow: 0 9px 12px -6px rgba(0, 0, 0, .2), 0 19px 29px 2px rgba(0, 0, 0, .14), 0 7px 36px 6px rgba(0, 0, 0, .12)
}

.mat-elevation-z20 {
  box-shadow: 0 10px 13px -6px rgba(0, 0, 0, .2), 0 20px 31px 3px rgba(0, 0, 0, .14), 0 8px 38px 7px rgba(0, 0, 0, .12)
}

.mat-elevation-z21 {
  box-shadow: 0 10px 13px -6px rgba(0, 0, 0, .2), 0 21px 33px 3px rgba(0, 0, 0, .14), 0 8px 40px 7px rgba(0, 0, 0, .12)
}

.mat-elevation-z22 {
  box-shadow: 0 10px 14px -6px rgba(0, 0, 0, .2), 0 22px 35px 3px rgba(0, 0, 0, .14), 0 8px 42px 7px rgba(0, 0, 0, .12)
}

.mat-elevation-z23 {
  box-shadow: 0 11px 14px -7px rgba(0, 0, 0, .2), 0 23px 36px 3px rgba(0, 0, 0, .14), 0 9px 44px 8px rgba(0, 0, 0, .12)
}

.mat-elevation-z24 {
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, .2), 0 24px 38px 3px rgba(0, 0, 0, .14), 0 9px 46px 8px rgba(0, 0, 0, .12)
}

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: OpenSans, "Helvetica Neue", sans-serif
}

.mat-badge-small .mat-badge-content {
  font-size: 6px
}

.mat-badge-large .mat-badge-content {
  font-size: 24px
}

.mat-h1,
.mat-headline,
.mat-typography h1 {
  font: 400 24px/32px OpenSans, "Helvetica Neue", sans-serif;
  margin: 0 0 16px
}

.mat-h2,
.mat-title,
.mat-typography h2 {
  font: 500 20px/32px OpenSans, "Helvetica Neue", sans-serif;
  margin: 0 0 16px
}

.mat-h3,
.mat-subheading-2,
.mat-typography h3 {
  font: 400 16px/28px OpenSans, "Helvetica Neue", sans-serif;
  margin: 0 0 16px
}

.mat-h4,
.mat-subheading-1,
.mat-typography h4 {
  font: 400 15px/24px OpenSans, "Helvetica Neue", sans-serif;
  margin: 0 0 16px
}

.mat-h5,
.mat-typography h5 {
  font: 400 11.62px/20px OpenSans, "Helvetica Neue", sans-serif;
  margin: 0 0 12px
}

.mat-h6,
.mat-typography h6 {
  font: 400 9.38px/20px OpenSans, "Helvetica Neue", sans-serif;
  margin: 0 0 12px
}

.mat-body-2,
.mat-body-strong {
  font: 500 14px/24px OpenSans, "Helvetica Neue", sans-serif
}

.mat-body,
.mat-body-1,
.mat-typography {
  font: 400 14px/20px OpenSans, "Helvetica Neue", sans-serif
}

.mat-body p,
.mat-body-1 p,
.mat-typography p {
  margin: 0 0 12px
}

.mat-caption,
.mat-small {
  font: 400 12px/20px OpenSans, "Helvetica Neue", sans-serif
}

.mat-display-4,
.mat-typography .mat-display-4 {
  font: 300 112px/112px OpenSans, "Helvetica Neue", sans-serif;
  margin: 0 0 56px;
  letter-spacing: -.05em
}

.mat-display-3,
.mat-typography .mat-display-3 {
  font: 400 56px/56px OpenSans, "Helvetica Neue", sans-serif;
  margin: 0 0 64px;
  letter-spacing: -.02em
}

.mat-display-2,
.mat-typography .mat-display-2 {
  font: 400 45px/48px OpenSans, "Helvetica Neue", sans-serif;
  margin: 0 0 64px;
  letter-spacing: -.005em
}

.mat-display-1,
.mat-typography .mat-display-1 {
  font: 400 34px/40px OpenSans, "Helvetica Neue", sans-serif;
  margin: 0 0 64px
}

.mat-bottom-sheet-container {
  font-family: OpenSans, "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 400
}

.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
}

.mat-card-title {
  font-size: 24px;
  font-weight: 400
}

.mat-card-content,
.mat-card-header .mat-card-title,
.mat-card-subtitle {
  font-size: 14px
}

.mat-checkbox {
  font-family: OpenSans, "Helvetica Neue", sans-serif
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px
}

.mat-chip {
  font-size: 13px;
  line-height: 18px
}

.mat-chip .mat-chip-remove.mat-icon,
.mat-chip .mat-chip-trailing-icon.mat-icon {
  font-size: 18px
}

.mat-table {
  font-family: OpenSans, "Helvetica Neue", sans-serif
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500
}

.mat-cell,
.mat-footer-cell {
  font-size: 14px
}

.mat-calendar {
  font-family: OpenSans, "Helvetica Neue", sans-serif
}

.mat-calendar-body {
  font-size: 13px
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400
}

.mat-dialog-title {
  font: 500 20px/32px OpenSans, "Helvetica Neue", sans-serif
}

.mat-expansion-panel-header {
  font-family: OpenSans, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400
}

.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
}

.mat-expansion-panel-content {
  font: 400 14px/20px OpenSans, "Helvetica Neue", sans-serif
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  font-family: OpenSans, "Helvetica Neue", sans-serif;
  /* height: 90px; */
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125
}

.mat-form-field-infix {
  padding: .5em 0;
  border-top: .84375em solid transparent
}

.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label,
.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  transform: translateY(-1.34375em) scale(.75);
  width: 133.33333%
}

.mat-form-field-can-float .mat-input-server[label]:not(:label-shown)+.mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(.75);
  width: 133.33334%
}

.mat-form-field-label-wrapper {
  top: -.84375em;
  padding-top: .84375em
}

.mat-form-field-label {
  top: 1.34375em
}

.mat-form-field-underline {
  bottom: 1.34375em
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: .66667em;
  top: calc(100% - 1.79167em)
}

.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: .6em 0
}

.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  transform: translateY(-1.28125em) scale(.75) perspective(100px) translateZ(.001px);
  -ms-transform: translateY(-1.28125em) scale(.75);
  width: 133.33333%
}

.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill+.mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(.75) perspective(100px) translateZ(.00101px);
  -ms-transform: translateY(-1.28124em) scale(.75);
  width: 133.33334%
}

.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown)+.mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(.75) perspective(100px) translateZ(.00102px);
  -ms-transform: translateY(-1.28123em) scale(.75);
  width: 133.33335%
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em
}

.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: .54167em;
  top: calc(100% - 1.66667em)
}

.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: .25em 0 .75em 0
}

.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -.5em
}

.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  transform: translateY(-.59375em) scale(.75);
  width: 133.33333%
}

.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown)+.mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-.59374em) scale(.75);
  width: 133.33334%
}

.mat-grid-tile-footer,
.mat-grid-tile-header {
  font-size: 14px
}

.mat-grid-tile-footer .mat-line,
.mat-grid-tile-header .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box
}

.mat-grid-tile-footer .mat-line:nth-child(n+2),
.mat-grid-tile-header .mat-line:nth-child(n+2) {
  font-size: 12px
}

.nput.mat-input-element {
  margin-top: -.0625em
}

.mat-menu-item {
  font-family: OpenSans, "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 400
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: OpenSans, "Helvetica Neue", sans-serif;
  font-size: 12px
}

.mat-radio-button {
  font-family: OpenSans, "Helvetica Neue", sans-serif
}

.mat-select {
  font-family: OpenSans, "Helvetica Neue", sans-serif
}

.mat-select-trigger {
  height: 1.125em
}

.mat-slide-toggle-content {
  font: 400 14px/20px OpenSans, "Helvetica Neue", sans-serif
}

.mat-slider-thumb-label-text {
  font-family: OpenSans, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500
}

.mat-stepper-horizontal,
.mat-stepper-vertical {
  font-family: OpenSans, "Helvetica Neue", sans-serif
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500
}

.mat-tab-group {
  font-family: OpenSans, "Helvetica Neue", sans-serif
}

.mat-tab-label,
.mat-tab-link {
  font-family: OpenSans, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500
}

.mat-tooltip.adminTooltip, .mat-tooltip.changeTooltip {
  font-size: 15px;
  min-width:100%;
}

.mat-tooltip.groupInfoTooltip {
  white-space: pre-line !important;
  font-size: 12px;
}

.mat-tooltip.userInfoTooltip {
  white-space: pre-line !important;
  font-size: 10px;
  min-width:100%;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 9px;
  padding-bottom: 9px
}

.mat-list-item {
  font-family: OpenSans, "Helvetica Neue", sans-serif
}

.mat-list-option {
  font-family: OpenSans, "Helvetica Neue", sans-serif
}

.mat-list .mat-list-item,
.mat-nav-list .mat-list-item,
.mat-selection-list .mat-list-item {
  font-size: 16px
}

.mat-list .mat-list-item .mat-line,
.mat-nav-list .mat-list-item .mat-line,
.mat-selection-list .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box
}

.mat-list .mat-list-item .mat-line:nth-child(n+2),
.mat-nav-list .mat-list-item .mat-line:nth-child(n+2),
.mat-selection-list .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px
}

.mat-list .mat-list-option,
.mat-nav-list .mat-list-option,
.mat-selection-list .mat-list-option {
  font-size: 16px
}

.mat-list .mat-list-option .mat-line,
.mat-nav-list .mat-list-option .mat-line,
.mat-selection-list .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box
}

.mat-list .mat-list-option .mat-line:nth-child(n+2),
.mat-nav-list .mat-list-option .mat-line:nth-child(n+2),
.mat-selection-list .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px
}

.mat-list .mat-subheader,
.mat-nav-list .mat-subheader,
.mat-selection-list .mat-subheader {
  font-family: OpenSans, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500
}

.mat-list[dense] .mat-list-item,
.mat-nav-list[dense] .mat-list-item,
.mat-selection-list[dense] .mat-list-item {
  font-size: 12px
}

.mat-list[dense] .mat-list-item .mat-line,
.mat-nav-list[dense] .mat-list-item .mat-line,
.mat-selection-list[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box
}

.mat-list[dense] .mat-list-item .mat-line:nth-child(n+2),
.mat-nav-list[dense] .mat-list-item .mat-line:nth-child(n+2),
.mat-selection-list[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px
}

.mat-list[dense] .mat-list-option,
.mat-nav-list[dense] .mat-list-option,
.mat-selection-list[dense] .mat-list-option {
  font-size: 12px
}

.mat-list[dense] .mat-list-option .mat-line,
.mat-nav-list[dense] .mat-list-option .mat-line,
.mat-selection-list[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box
}

.mat-list[dense] .mat-list-option .mat-line:nth-child(n+2),
.mat-nav-list[dense] .mat-list-option .mat-line:nth-child(n+2),
.mat-selection-list[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px
}

.mat-list[dense] .mat-subheader,
.mat-nav-list[dense] .mat-subheader,
.mat-selection-list[dense] .mat-subheader {
  font-family: OpenSans, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500
}

.mat-option {
  font-family: OpenSans, "Helvetica Neue", sans-serif;
  font-size: 16px
}

.mat-optgroup-label {
  font: 500 14px/24px OpenSans, "Helvetica Neue", sans-serif
}

.mat-tree {
  font-family: OpenSans, "Helvetica Neue", sans-serif
}

.mat-tree-node {
  font-weight: 400;
  font-size: 14px
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none
}

.cdk-global-overlay-wrapper,
.cdk-overlay-container {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000
}

.cdk-overlay-container:empty {
  display: none
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

@media(max-width: 768px) {
  .cdk-overlay-pane {
    min-width: 95% !important;
  }
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity .4s cubic-bezier(.25, .8, .25, 1);
  opacity: 0
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1
}

@media screen and (-ms-high-contrast:active) {
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: .6
  }
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, .288)
}

.cdk-overlay-transparent-backdrop,
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll
}

.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation-name: cdk-text-field-autofill-start
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation-name: cdk-text-field-autofill-end
}

.extarea.cdk-textarea-autosize {
  resize: none
}

.extarea.cdk-textarea-autosize-measuring {
  height: auto !important;
  overflow: hidden !important;
  padding: 2px 0 !important;
  box-sizing: content-box !important
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, .1)
}

.mat-option {
  color: rgba(0, 0, 0, .87)
}

.mat-option:focus:not(.mat-option-disabled),
.mat-option:hover:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, .04)
}

.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, .04)
}

.mat-option.mat-active {
  background: rgba(0, 0, 0, .04);
  color: rgba(0, 0, 0, .87)
}

.mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, .38)
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: var(--color-primary)
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: var(--color-primary-lighter)
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336
}

.mat-optgroup-label {
  color: rgba(0, 0, 0, .54)
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, .38)
}

.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, .54)
}

.mat-pseudo-checkbox::after {
  color: #fafafa
}

.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate,
.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate {
  background: var(--color-primary-lighter)
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: var(--color-primary)
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0
}

.mat-app-background {
  background-color: #f3f3f3;
  color: rgba(0, 0, 0, .87)
}

.mat-theme-loaded-marker {
  display: none
}

.mat-autocomplete-panel {
  background: var(--color-lightest);
  color: rgba(0, 0, 0, .87)
}

.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: var(--color-lightest)
}

.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, .87)
}

.mat-badge-content {
  color: var(--color-lightest);
  background: var(--color-primary)
}

.mat-badge-accent .mat-badge-content {
  background: var(--color-primary-lighter);
  color: var(--color-lightest)
}

.mat-badge-warn .mat-badge-content {
  color: var(--color-lightest);
  background: #f44336
}

.mat-badge {
  position: relative
}

.mat-badge-hidden .mat-badge-content {
  display: none
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;

  transition: transform .2s ease-in-out;
  transform: scale(.6);

}

.mat-badge-content.mat-badge-active {
  transform: none
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px
}

@media screen and (-ms-high-contrast:active) {
  .mat-badge-small .mat-badge-content {
    outline: solid 1px;
    border-radius: 0
  }
}

.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px
}

.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px
}

.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px
}

[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px
}

.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px
}

[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px
}

.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px
}

[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px
}

.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px
}

[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px
}

@media screen and (-ms-high-contrast:active) {
  .mat-badge-medium .mat-badge-content {
    outline: solid 1px;
    border-radius: 0
  }
}

.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px
}

.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px
}

.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px
}

[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px
}

.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px
}

[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px
}

.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px
}

[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px
}

.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px
}

[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px
}

@media screen and (-ms-high-contrast:active) {
  .mat-badge-large .mat-badge-content {
    outline: solid 1px;
    border-radius: 0
  }
}

.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px
}

.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px
}

.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px
}

[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px
}

.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px
}

[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px
}

.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px
}

[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px
}

.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px
}

[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px
}

.mat-bottom-sheet-container {
  background: var(--color-lightest);
  color: rgba(0, 0, 0, .87)
}

.mat-card {
  background: var(--color-lightest);
  color: rgba(0, 0, 0, .87)
}

.mat-card-subtitle {
  color: rgba(0, 0, 0, .54)
}

.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, .54)
}

.mat-checkbox-checkmark {
  fill: #fafafa
}

.mat-checkbox-checkmark-path {
  stroke: #fafafa !important
}

@media screen and (-ms-high-contrast:black-on-white) {
  .mat-checkbox-checkmark-path {
    stroke: #000 !important
  }
}

.mat-checkbox-mixedmark {
  background-color: #fafafa
}

.mat-checkbox-checked.mat-primary .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background {
  background-color: var(--color-primary)
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: var(--color-primary-lighter)
}

.mat-checkbox-checked.mat-warn .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background {
  background-color: #f44336
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background,
.mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0
}

.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0
}

.mat-checkbox-disabled .mat-checkbox-label {
  color: #b0b0b0
}

@media screen and (-ms-high-contrast:active) {
  .mat-checkbox-disabled {
    opacity: .5
  }
}

@media screen and (-ms-high-contrast:active) {
  .mat-checkbox-background {
    background: 0 0
  }
}

.mat-checkbox:not(.mat-checkbox-disabled).mat-primary .mat-checkbox-ripple .mat-ripple-element {
  background-color: rgba(63, 81, 181, .26)
}

.mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element {
  background-color: rgba(255, 64, 129, .26)
}

.mat-checkbox:not(.mat-checkbox-disabled).mat-warn .mat-checkbox-ripple .mat-ripple-element {
  background-color: rgba(244, 67, 54, .26)
}

.mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, .87)
}

.mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, .87);
  opacity: .4
}

.mat-chip.mat-standard-chip .mat-chip-remove:hover {
  opacity: .54
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: var(--color-primary);
  color: var(--color-lightest)
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: var(--color-lightest);
  opacity: .4
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove:hover {
  opacity: .54
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: var(--color-lightest)
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: var(--color-lightest);
  opacity: .4
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove:hover {
  opacity: .54
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: var(--color-primary-lighter);
  color: var(--color-lightest)
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: var(--color-lightest);
  opacity: .4
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove:hover {
  opacity: .54
}

.mat-table {
  background: var(--color-lightest)
}

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, .54);
  border-right: 2px solid rgba(0, 0, 0, .54)
}

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, .54)
}

.mat-progress-bar-background {
  fill: #c5cae9
}

.mat-progress-bar-buffer {
  background-color: #c5cae9
}

.mat-progress-bar-fill::after {
  background-color: var(--color-primary)
}

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: var(--color-primary-lighter)
}

.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: var(--color-primary-lighter)
}

.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: var(--color-primary-lighter)
}

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #ffcdd2
}

.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #ffcdd2
}

.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: var(--color-primary)
}

.mat-progress-spinner.mat-accent circle,
.mat-spinner.mat-accent circle {
  stroke: var(--color-primary-lighter)
}

.mat-progress-spinner.mat-warn circle,
.mat-spinner.mat-warn circle {
  stroke: #f44336
}

.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, .54)
}

.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, .38)
}

.mat-radio-disabled .mat-radio-inner-circle,
.mat-radio-disabled .mat-radio-ripple .mat-ripple-element {
  background-color: rgba(0, 0, 0, .38)
}

.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, .38)
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--color-primary)
}

.mat-radio-button.mat-primary .mat-radio-inner-circle {
  background-color: var(--color-primary)
}

.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element {
  background-color: rgba(63, 81, 181, .26)
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--color-primary-lighter)
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: var(--color-primary-lighter)
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: rgba(255, 64, 129, .26)
}

.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336
}

.mat-radio-button.mat-warn .mat-radio-inner-circle {
  background-color: #f44336
}

.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element {
  background-color: rgba(244, 67, 54, .26)
}

.mat-select-content,
.mat-select-panel-done-animating {
  background: var(--color-lightest)
}

.mat-select-value {
  color: rgba(0, 0, 0, .87)
}

.mat-select-placeholder {
  color: rgba(0, 0, 0, .42)
}

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, .38)
}

.mat-select-arrow {
  color: rgba(0, 0, 0, .54)
}

.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, .12)
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: var(--color-primary)
}

.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: var(--color-primary-lighter)
}

.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336
}

.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336
}

.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, .38)
}

.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, .87)
}

.mat-drawer {
  background-color: var(--color-lightest);
  color: rgba(0, 0, 0, .87)
}

.mat-drawer.mat-drawer-push {
  background-color: var(--color-lightest)
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, .6)
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: #e91e63
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: rgba(233, 30, 99, .5)
}

.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: rgba(0, 0, 0, .06)
}

.mat-slide-toggle .mat-ripple-element {
  background-color: rgba(233, 30, 99, .12)
}

.mat-slide-toggle.mat-primary.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: var(--color-primary-lighter)
}

.mat-slide-toggle.mat-primary.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: rgba(63, 81, 181, .5)
}

.mat-slide-toggle.mat-primary:not(.mat-checked) .mat-ripple-element {
  background-color: rgba(0, 0, 0, .06)
}

.mat-slide-toggle.mat-primary .mat-ripple-element {
  background-color: rgba(63, 81, 181, .12)
}

.mat-slide-toggle.mat-warn.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: #f44336
}

.mat-slide-toggle.mat-warn.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, .5)
}

.mat-slide-toggle.mat-warn:not(.mat-checked) .mat-ripple-element {
  background-color: rgba(0, 0, 0, .06)
}

.mat-slide-toggle.mat-warn .mat-ripple-element {
  background-color: rgba(244, 67, 54, .12)
}

.mat-disabled .mat-slide-toggle-thumb {
  background-color: #bdbdbd
}

.mat-disabled .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, .1)
}

.mat-slide-toggle-thumb {
  background-color: #fafafa
}

.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, .38)
}

.mat-slider-track-background {
  background-color: rgba(0, 0, 0, .26)
}

.mat-primary .mat-slider-thumb,
.mat-primary .mat-slider-thumb-label,
.mat-primary .mat-slider-track-fill {
  background-color: var(--color-primary)
}

.mat-primary .mat-slider-thumb-label-text {
  color: var(--color-lightest)
}

.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label,
.mat-accent .mat-slider-track-fill {
  background-color: var(--color-primary-lighter)
}

.mat-accent .mat-slider-thumb-label-text {
  color: var(--color-lightest)
}

.mat-warn .mat-slider-thumb,
.mat-warn .mat-slider-thumb-label,
.mat-warn .mat-slider-track-fill {
  background-color: #f44336
}

.mat-warn .mat-slider-thumb-label-text {
  color: var(--color-lightest)
}

.mat-slider-focus-ring {
  background-color: rgba(255, 64, 129, .2)
}

.cdk-focused .mat-slider-track-background,
.mat-slider:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, .38)
}

.mat-slider-disabled .mat-slider-thumb,
.mat-slider-disabled .mat-slider-track-background,
.mat-slider-disabled .mat-slider-track-fill {
  background-color: rgba(0, 0, 0, .26)
}

.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, .26)
}

.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, .12)
}

.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, .87)
}

.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, .26)
}

.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, .26);
  background-color: transparent
}

.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb,
.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb {
  border-color: rgba(0, 0, 0, .38)
}

.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb,
.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, .26)
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, .7)
}

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, .7), rgba(0, 0, 0, .7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(.0001deg, rgba(0, 0, 0, .7), rgba(0, 0, 0, .7) 2px, transparent 0, transparent)
}

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, .7), rgba(0, 0, 0, .7) 2px, transparent 0, transparent)
}

.mat-step-header.cdk-keyboard-focused,
.mat-step-header.cdk-program-focused,
.mat-step-header:hover {
  background-color: rgba(0, 0, 0, .04)
}

.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, .38)
}

.mat-step-header .mat-step-icon-not-touched {
  background-color: rgba(0, 0, 0, .38);
}

.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, .87)
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, .12)
}

.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, .12)
}

.mat-tab-header,
.mat-tab-nav-bar {
  border-bottom: 1px solid rgba(0, 0, 0, .12)
}

.mat-tab-group-inverted-header .mat-tab-header,
.mat-tab-group-inverted-header .mat-tab-nav-bar {
  border-top: 1px solid rgba(0, 0, 0, .12);
  border-bottom: none
}

.mat-tab-label,
.mat-tab-link {
  color: rgba(0, 0, 0, .87)
}

.mat-tab-label.mat-tab-disabled,
.mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, .38)
}

.mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, .87)
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, .38)
}

.mat-tab-group[class*=mat-background-] .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none
}

.mat-tab-group.mat-warn .mat-tab-label:not(.mat-tab-disabled):focus,
.mat-tab-group.mat-warn .mat-tab-link:not(.mat-tab-disabled):focus,
.mat-tab-nav-bar.mat-warn .mat-tab-label:not(.mat-tab-disabled):focus,
.mat-tab-nav-bar.mat-warn .mat-tab-link:not(.mat-tab-disabled):focus {
  background-color: rgba(255, 205, 210, .3)
}

.mat-tab-group.mat-warn .mat-ink-bar,
.mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336
}

.mat-tab-group.mat-warn.mat-background-warn .mat-ink-bar,
.mat-tab-nav-bar.mat-warn.mat-background-warn .mat-ink-bar {
  background-color: var(--color-lightest)
}

.mat-tab-group.mat-background-primary .mat-tab-label:not(.mat-tab-disabled):focus,
.mat-tab-group.mat-background-primary .mat-tab-link:not(.mat-tab-disabled):focus,
.mat-tab-nav-bar.mat-background-primary .mat-tab-label:not(.mat-tab-disabled):focus,
.mat-tab-nav-bar.mat-background-primary .mat-tab-link:not(.mat-tab-disabled):focus {
  background-color: rgba(197, 202, 233, .3)
}

.mat-tab-group.mat-background-primary .mat-tab-header,
.mat-tab-group.mat-background-primary .mat-tab-links,
.mat-tab-nav-bar.mat-background-primary .mat-tab-header,
.mat-tab-nav-bar.mat-background-primary .mat-tab-links {
  background-color: var(--color-primary)
}

.mat-tab-group.mat-background-primary .mat-tab-label,
.mat-tab-group.mat-background-primary .mat-tab-link,
.mat-tab-nav-bar.mat-background-primary .mat-tab-label,
.mat-tab-nav-bar.mat-background-primary .mat-tab-link {
  color: var(--color-lightest)
}

.mat-tab-group.mat-background-primary .mat-tab-label.mat-tab-disabled,
.mat-tab-group.mat-background-primary .mat-tab-link.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-primary .mat-tab-label.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, .4)
}

.mat-tab-group.mat-background-primary .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-chevron {
  border-color: var(--color-lightest)
}

.mat-tab-group.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, .4)
}

.mat-tab-group.mat-background-primary .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, .12)
}

.mat-tab-group.mat-background-accent .mat-tab-label:not(.mat-tab-disabled):focus,
.mat-tab-group.mat-background-accent .mat-tab-link:not(.mat-tab-disabled):focus,
.mat-tab-nav-bar.mat-background-accent .mat-tab-label:not(.mat-tab-disabled):focus,
.mat-tab-nav-bar.mat-background-accent .mat-tab-link:not(.mat-tab-disabled):focus {
  background-color: rgba(255, 128, 171, .3)
}

.mat-tab-group.mat-background-accent .mat-tab-header,
.mat-tab-group.mat-background-accent .mat-tab-links,
.mat-tab-nav-bar.mat-background-accent .mat-tab-header,
.mat-tab-nav-bar.mat-background-accent .mat-tab-links {
  background-color: var(--color-primary-lighter)
}

.mat-tab-group.mat-background-accent .mat-tab-label,
.mat-tab-group.mat-background-accent .mat-tab-link,
.mat-tab-nav-bar.mat-background-accent .mat-tab-label,
.mat-tab-nav-bar.mat-background-accent .mat-tab-link {
  color: var(--color-lightest)
}

.mat-tab-group.mat-background-accent .mat-tab-label.mat-tab-disabled,
.mat-tab-group.mat-background-accent .mat-tab-link.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-accent .mat-tab-label.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, .4)
}

.mat-tab-group.mat-background-accent .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-chevron {
  border-color: var(--color-lightest)
}

.mat-tab-group.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, .4)
}

.mat-tab-group.mat-background-accent .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, .12)
}

.mat-tab-group.mat-background-warn .mat-tab-label:not(.mat-tab-disabled):focus,
.mat-tab-group.mat-background-warn .mat-tab-link:not(.mat-tab-disabled):focus,
.mat-tab-nav-bar.mat-background-warn .mat-tab-label:not(.mat-tab-disabled):focus,
.mat-tab-nav-bar.mat-background-warn .mat-tab-link:not(.mat-tab-disabled):focus {
  background-color: rgba(255, 205, 210, .3)
}

.mat-tab-group.mat-background-warn .mat-tab-header,
.mat-tab-group.mat-background-warn .mat-tab-links,
.mat-tab-nav-bar.mat-background-warn .mat-tab-header,
.mat-tab-nav-bar.mat-background-warn .mat-tab-links {
  background-color: #f44336
}

.mat-tab-group.mat-background-warn .mat-tab-label,
.mat-tab-group.mat-background-warn .mat-tab-link,
.mat-tab-nav-bar.mat-background-warn .mat-tab-label,
.mat-tab-nav-bar.mat-background-warn .mat-tab-link {
  color: var(--color-lightest)
}

.mat-tab-group.mat-background-warn .mat-tab-label.mat-tab-disabled,
.mat-tab-group.mat-background-warn .mat-tab-link.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-warn .mat-tab-label.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, .4)
}

.mat-tab-group.mat-background-warn .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-chevron {
  border-color: var(--color-lightest)
}

.mat-tab-group.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, .4)
}

.mat-tab-group.mat-background-warn .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, .12)
}

.mat-tree {
  background: var(--color-lightest)
}

.mat-tree-node {
  color: rgba(0, 0, 0, .87)
}

.customToggle.mat-slide-toggle .mat-slide-toggle-label {
  display: flex;
  flex-direction: row-reverse;
  padding-top: 5px;
}

.customToggle.mat-slide-toggle .mat-slide-toggle-label .mat-slide-toggle-bar {
  width: 34px;
  height: 16px;
}

.customToggle.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-label .mat-slide-toggle-bar {
  background-color: var(--color-primary-lighter) !important;
}

.customToggle.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-label .mat-slide-toggle-thumb {
  background-color: var(--color-lightest) !important;
}

.customToggle.mat-slide-toggle .mat-slide-toggle-label .mat-slide-toggle-thumb {
  width: 14px;
  height: 14px;
}

.customToggle.mat-slide-toggle .mat-slide-toggle-label .mat-slide-toggle-thumb-container {
  top: 1px;
}

.customToggle.mat-slide-toggle.mat-checked .mat-slide-toggle-label .mat-slide-toggle-thumb-container {
  transform: translate3d(19px, 0, 0);
  top: 1px;
}

.customToggle.mat-slide-toggle .mat-slide-toggle-content {
  padding-right: 2rem;
}

.dynamic-data-method-option .mat-radio-label-content {
  font-weight: normal;
  display: inline-flex;
  align-items: center;
}

.dynamic-data-controls .mat-checkbox .mat-checkbox-layout .mat-checkbox-label {
  font-weight: normal;
}

.form-screen-tab-group .mat-tab-group .mat-tab-header {
  width: 80%;
}

.form-screen-tab-group .mat-tab-group .mat-tab-label {
  padding: 0px;
  cursor: all-scroll;
}

.form-screen-tab-group .mat-tab-group .mat-tab-label-content {
  width: 100%;
  height: 100%;
}

.controlsPanel .mat-expansion-panel .mat-expansion-panel-body {
  padding: 0px;
}

.controlsPanel .mat-expansion-panel .mat-list-item-content {
  padding: 0px !important;
}

.controlsPanel .mat-expansion-panel .mat-list-text {
  height: 100%;
  display: flex;
  justify-content: center;
}

.interaction-elements-container .ui-accordion .ui-accordion-header {
  background: var(--color-lightest) !important;
  border-top: 0 !important;
}

.interaction-elements-container .ui-accordion .ui-state-default {
  border: 0 !important;
}

.interaction-elements-container .ui-accordion .mat-list-text {
  display: flex;
  width: 100%;
  height: 100% !important;
}

.interaction-condition-builder .mat-tab-group .mat-tab-header {
  width: 80%;
}

.interaction-condition-builder .mat-tab-group .mat-tab-label {
  padding: 0px !important;
  cursor: all-scroll;
}

.interaction-condition-builder .mat-tab-group .mat-tab-label-content {
  width: 100%;
  height: 100%;
}

.workflow-builder .mat-expansion-panel .mat-expansion-panel-body {
  display: flex;
  justify-content: center;
  padding-bottom: 0 !important;
}

.workflow-builder .mat-tab-group .mat-tab-header {
    width: 80%;
}

.workflow-builder .mat-tab-group .mat-tab-label {
  padding: 0px !important;
  cursor: all-scroll;
}

.workflow-builder .mat-tab-group .mat-tab-label-content {
  width: 100%;
  height: 100%;
}

.workflow-builder .mat-list-item-content {
  padding: 0 !important;
}

.workflow-builder .mat-list-item-ripple {
  display: none;
}

.mat-stepper-horizontal {
  overflow: hidden;
}
