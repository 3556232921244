@use '@angular/material' as mat;
// @import url('https://fonts.googleapis.com/css?family=Palanquin|Source+Sans+Pro');
/* @import 'variables.scss'; */
@import 'palettes.scss';

@import "~font-awesome/css/font-awesome.min.css";
@import "~primeicons/primeicons.css";
@import "~primeng/resources/themes/nova/theme.css";
@import "~primeng/resources/primeng.min.css";
@import "~intro.js/minified/introjs.min.css";

/* ATB-styled Angular Material rules */
@import "eva-theme.css";
@import "~angular-calendar/scss/angular-calendar.scss";

/* import the AG-grid styles */
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-balham.css";
@import "~ag-grid-community/dist/styles/ag-theme-dark.css";
@import "~ag-grid-community/src/styles/ag-theme-material/sass/ag-theme-material-mixin";

@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

@include mat.core();

/* Initalize theming using Material's sass mixins */
/* $angular-primary: mat.define-palette(mat.$teal-palette, 500, 100, 900);
$angular-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$angular-warn: mat.define-palette(mat.$red-palette); */

/*
* The numbers behind the palette variable name select particular shades of chosen color for default, lighter and darker variants. If no numbers are provided Angular Material selects sensible defaults $default: 500, $lighter: 100, $darker: 700.
*/
$eva-primary: mat.define-palette($md-eva-blue);
$eva-accent: mat.define-palette($md-eva-purple);

/* Material Themes */
/* $angular-light-theme: mat.define-light-theme($angular-primary, $angular-accent, $angular-warn);
$angular-dark-theme: mat.define-dark-theme($angular-primary, $angular-accent, $angular-warn); */

$eva-light-theme: mat.define-light-theme($eva-primary, $eva-accent);
$eva-dark-theme: mat.define-dark-theme($eva-primary, $eva-accent);

body.theme-eva-light {
  @include mat.all-component-themes($eva-light-theme);

  /* Colors */
  --color-light-gray: #{map-get(mat.$grey-palette, 300)};
  --color-primary: #005eb8;
  --color-primary-lighter: #009cde;
  --color-primary-darker: #00407b;
  --color-secondary: #9756b3;
  --color-danger: #ec0000;
  --color-dark: #2b353d;
  --color-darkest: #000;
  --color-drag-hover: #999;
  --color-body: #444;
  --color-heading: #000;
  --color-menu-item: #fff;
  --color-lightest: #fff;
  --color-opposite: #000;
  --color-background: #fafafa;
  --color-shade-100: #{map-get(mat.$grey-palette, 100)};
  --color-shade-200: #{map-get(mat.$grey-palette, 200)};
  --color-shade-300: #{map-get(mat.$grey-palette, 300)};
  --color-shade-400: #{map-get(mat.$grey-palette, 400)};
  --color-shade-500: #{map-get(mat.$grey-palette, 500)};
  --color-shade-600: #{map-get(mat.$grey-palette, 600)};
  --color-shade-700: #{map-get(mat.$grey-palette, 700)};
  --color-shade-800: #{map-get(mat.$grey-palette, 800)};
  --color-shade-900: #{map-get(mat.$grey-palette, 900)};
  
  /* Fonts */
  --font-body: 'Open Sans', sans-serif;
  --font-heading: 'Palanquin', sans-serif;

  /* Animation */
  --transition-speed: 300ms;

  a {
    color: var(--color-primary);
  }

  .multi-view-container .multi-view-component > .mat-tab-group > mat-tab-header {
    background: map-get(mat.$grey-palette, 200);
    box-shadow: inset 0px -1px 0 rgba(0,0,0,0.1);
  }

  .multi-view-container .multi-view-component > .mat-tab-group > mat-tab-header .mat-tab-label.mat-tab-label-active {
    background-color: map-get(mat.$light-theme-background-palette, background);
    box-shadow: 0px 1px 4px rgba(0,0,0,0.35);
  }

  .multi-view-container .button-close-tab {
    background: map-get(mat.$grey-palette, 200);
  }

  .chat-icon-container {
    /* background: map-get(mat.$grey-palette, 200);
    color: mat.get-color-from-palette(map-get($eva-light-theme, accent)); */
    background: mat.get-color-from-palette(map-get($eva-light-theme, primary));
    color: #fff;
  }

  .notifications-header {
    background: #fff;
  }

  .document-component-header-container {
    .document-component-header {
      background: var(--color-lightest);
    }
  }

  .result-container .search-result {
    background-color: #fff;
    .search-result-actions {
      background: var(--color-shade-100);
      .button-launch-process {
        color: var(--color-primary);
      }
    }
  }

  /* Fix for input underline */
  .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
    background-color: transparent;
  }
}

body.theme-eva-dark {
  @include mat.all-component-themes($eva-dark-theme);

  /* Interpolation required when using sass functions in color variables - eg. #{ SASS SYNTAX HERE } */
  --color-light-gray: #{map-get(mat.$grey-palette, 600)};
  --color-primary: #005eb8;
  --color-primary-lighter: #009cde;
  --color-primary-darker: #00407b;
  --color-secondary: #9756b3;
  --color-danger: #ec0000;
  --color-dark: #2b353d;
  --color-darkest: #000;
  --color-drag-hover: #999;
  --color-body: #444;
  --color-heading: #000;
  --color-menu-item: #fff;
  --color-lightest: #000;
  --color-opposite: #fff;
  --color-background: #303030;
  --color-shade-100: #{map-get(mat.$grey-palette, 900)};
  --color-shade-200: #{map-get(mat.$grey-palette, 800)};
  --color-shade-300: #{map-get(mat.$grey-palette, 700)};
  --color-shade-400: #{map-get(mat.$grey-palette, 600)};
  --color-shade-500: #{map-get(mat.$grey-palette, 500)};
  --color-shade-600: #{map-get(mat.$grey-palette, 400)};
  --color-shade-700: #{map-get(mat.$grey-palette, 300)};
  --color-shade-800: #{map-get(mat.$grey-palette, 200)};
  --color-shade-900: #{map-get(mat.$grey-palette, 100)};
  
  /* Fonts */
  --font-body: 'Open Sans', sans-serif;
  --font-heading: 'Palanquin', sans-serif;

  /* Animation */
  --transition-speed: 300ms;

  /* Styling for angular-calendar for RCM */
  /* For a list of all variables and how they are used, */
  /* see https://github.com/mattlewis92/angular-calendar/tree/master/projects/angular-calendar/src/variables.scss */
  $bg-dark-primary: #1f262d;
  $bg-dark-secondary: #394046;
  $bg-active: #2c343a;
  $text-color: #d5d6d7;
  $border-color: rgba(0, 0, 0, 0.6);

  @include cal-theme(
    (
      bg-primary: $bg-dark-primary,
      bg-secondary: $bg-dark-secondary,
      weekend-color: indianred,
      bg-active: $bg-active,
      border-color: $border-color,
      gray: $bg-dark-secondary,
      today-bg: $bg-dark-secondary,
      event-color-primary: $bg-dark-secondary
    )
  );

  a {
    color: var(--color-primary-lighter);
  }

  .multi-view-container .multi-view-component > .mat-tab-group > mat-tab-header {
    background: map-get(mat.$grey-palette, 900);
    box-shadow: inset 0px -1px 0 rgba(0,0,0,0.2);
  }

  .multi-view-container .multi-view-component > .mat-tab-group > mat-tab-header .mat-tab-label.mat-tab-label-active {
    background-color: map-get(mat.$dark-theme-background-palette, background);
    box-shadow: 0px 1px 4px rgba(0,0,0,0.35);
  }

  .multi-view-container .button-close-tab {
    background: map-get(mat.$grey-palette, 900);
  }

  .chat-icon-container {
    background: mat.get-color-from-palette(map-get($eva-dark-theme, accent));
    color: #fff;
  }

  .notifications-header {
    background: var(--color-shade-200);
  }

  .knowledge-document-editor {
    .fr-wrapper {
      border-color: var(--color-shade-200);
    }
    .fr-toolbar {
      border-color: var(--color-shade-200);
    }
  }

  .document-component-header-container {
    .document-component-header {
        background: var(--color-shade-200);
    }
  }

  .result-container .search-result {
    background-color: var(--color-shade-200);
    .search-result-actions {
      background: transparent;
      border-top: 1px solid var(--color-shade-300);
      .button-launch-process {
        background: var(--color-shade-300);
      }
    }
  }

  /* Fix for input underline */
  .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
    background-color: transparent;
  }
}

html,
body {
  height: 100vh;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 16px;
  line-height: 1.42857143;
  margin: 0;
}

body {
  min-height: 100vh;
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-heading);
  font-weight: 500;
  line-height: 1.1;
}

h1 {
  font-size: 2.2em;
}

h2 {
  font-size: 1.65em;
}

h3 {
  font-size: 1.35em;
}

h4 {
  font-size: 1.15em;
}

a {
  font-weight: bold;
  &:hover {
    text-decoration: underline;
  }
}

hr {
  border-color: var(--color-light-gray);
}

.knowledge-revisions-radio-group {
  padding-bottom: 1rem;
}

.knowledge-revisions-radio-group.mat-radio-group .mat-radio-button {
  padding: 0.25rem 0.5rem;
  margin: 0 0.5rem;
  border-radius: 1rem;
  &:hover {
    background: var(--color-shade-300);
  }
}

.ais-Pagination-link {
  padding: .5rem 1rem;
  border-color: var(--color-shade-300);
  color: var(--color-primary-lighter);
  &:hover {
    background: var(--color-shade-300);
    color: var(--color-opposite);
    text-decoration: none;
  }
}

.ais-Pagination-item--selected .ais-Pagination-link {
  color: #fff;
  background-color: var(--color-primary-lighter) !important;
  border-color: var(--color-primary-lighter) !important;
}

#wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.rcm-move-message {
  padding: 1rem;
  margin-bottom: 1rem;
  background: orange;
}

/** Adds padding to all sibling buttons on their right side */
.button-row-right {
  button + button {
    margin-right: 0.5rem;
  }
}

/** Adds padding to all sibling buttons on their left side */
.button-row-left {
  button + button {
    margin-left: 0.5rem;
  }
}

/** Fix for froala editor and the panes so the popup dialog appears above the editor */
.fr-popup.fr-active {
  z-index: 100 !important;
}

.fr-view ul {
  list-style-position: initial;
}

.button-lg {
  width: auto;
  min-width: 110px;
  height: 44px;
}

.builder-actions {
  z-index: 2; 
  position: sticky;
  bottom: 0; 
  left: 0;
  right: 0;
  padding: .75rem;
  margin-left: -1rem;
  margin-right: -1rem;
  border-top: 1px solid var(--color-shade-400);
  background-color: var(--color-background);
}

.content {
  padding: 2vw;
  flex: 1
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.box {
  border-radius: 0px;
  margin-top: 5px;
}

.input {
  margin-bottom: 5px;
}

.ui-tree {
  width: 100% !important;
}

.app-loading {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: relative;
}

.selectableTreeNode {
  color: black
}

.unSelectableTreeNode {
  color: gray
}

.margin-left-auto {
  margin-left: auto !important;
}

.fullWidth {
  width: 100% !important;
}

.ui-accordion-content {
  padding: 0ex !important;
}

span.ui-button-text.ui-clickable {
  display: inline !important;
}

#ConditionSetZone .mat-tab-label {
  height: 32px !important;
  padding: 0 12px;
  min-width: 120px;
}

.ui-multiselect {
  width: 100% !important;
}

.ui-multiselect .ui-multiselect-label {
  width: 100% !important;
}

#actionTabGroup .mat-tab-labels {
  flex-direction: column;
}

#actionTabGroup .mat-tab-label-active {
  background-color: var(--color-primary) !important;
  opacity: 1;
  color: #ffffff;
}

#actionTabGroup .mat-tab-label {
  padding: 70px 10px;
}

#actionTabGroup .mat-ink-bar {
  visibility: hidden !important;
}

.introductionTabs .mat-ink-bar {
  background-color: #000000 !important;
}

.reputationChangeTabs .mat-ink-bar {
  background-color: #000000 !important;
}

#actionTabGroup .mat-tab-body-wrapper {
  flex: 1;
}

#actionTabGroup .mat-tab-body {
  background-image: radial-gradient(circle at 51% -105%, #7ebefc, #ffffff);
  border: solid 1px var(--color-light-gray);
  padding: 25px;
}

.mat-tab-label:not(.mat-tab-label-active) .askIcon {
  width: 50px;
  height: 50px;
  background: url('../assets/images/ask-grey.svg') no-repeat center;
}

.mat-tab-label:not(.mat-tab-label-active) .learnIcon {
  width: 50px;
  height: 50px;
  background: url('../assets/images/learn-grey.svg') no-repeat center;
}

.mat-tab-label:not(.mat-tab-label-active) .doIcon {
  width: 50px;
  height: 50px;
  background: url('../assets/images/do-grey.svg') no-repeat center;
}

.mat-tab-label.mat-tab-label-active .askIcon {
  width: 50px !important;
  height: 50px !important;
  background: url('../assets/images/ask-white.svg') no-repeat center !important;
}

.mat-tab-label.mat-tab-label-active .learnIcon {
  width: 50px;
  height: 50px;
  background: url('../assets/images/learn-white.svg') no-repeat center;
}

.mat-tab-label.mat-tab-label-active .doIcon {
  width: 50px;
  height: 50px;
  background: url('../assets/images/do-white.svg') no-repeat center;
}

/* --- End --- Landing Page Custom Tab Style */

.multi-view-container .button-close-tab {
  margin-left: 6px;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  line-height: 20px;
}

.mat-horizontal-stepper-header-container .mat-horizontal-stepper-header {
  display: block !important;
  height: 40px !important;
}


.workflow-interaction-horizontal-stepper {
  width: 100%;
}

.workflow-interaction-horizontal-stepper .mat-step-label {
  text-align: center;
}

.workflow-interaction-horizontal-stepper .mat-horizontal-stepper-header {
  height: 70px !important;
}

.workflow-interaction-horizontal-stepper .mat-horizontal-stepper-header-container {
  height: 90px;
  position: fixed;
  width: 700px;
  background-color: var(--color-lightest);
  z-index: 2;
}

.workflow-interaction-horizontal-stepper .mat-horizontal-content-container {
  margin-top: 90px;
}

.workflow-interaction-horizontal-stepper .mat-tab-header {
  width: 295px !important;
}

.mat-stepper-horizontal-line {
  margin: 5px -75px !important;
}

.mat-step-label {
  min-width: 120px !important;
}

.mat-step-label.mat-step-label-active {
  color: var(--color-primary) !important;
}

.mat-horizontal-stepper-header {
  padding: 0 4px !important;
}

.mat-horizontal-stepper-header-container {
  align-items: end !important;
}

.mat-horizontal-stepper-header[aria-selected="true"] .mat-step-icon {
  width: 14px;
  height: 14px;
  border: solid 4px rgb(211, 211, 211);
  background-color: var(--color-primary) !important;
  display: block;
  margin-right: auto !important;
  margin-left: auto;
  margin-bottom: -4px;
}

.mat-horizontal-stepper-header[aria-selected="false"] .mat-step-icon {
  width: 10px;
  height: 10px;
  border: solid 2px var(--color-primary);
  background-color: var(--color-primary) !important;
  display: block;
  margin-right: auto !important;
  margin-left: auto;
}

.mat-horizontal-stepper-header-container .mat-step-icon-not-touched {
  width: 10px;
  height: 10px;
  border: solid 2px #ffffff;
  background-color: #dedfe1;
  display: block;
  margin-right: auto !important;
  margin-left: auto;
}

.mat-step-header .mat-step-icon span,
.mat-step-header .mat-step-icon-not-touched span {
  display: none;
}

.mat-horizontal-stepper-content .mat-card {
  max-width: 85%;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 40px 30px;
  border-radius: 0px;
}

.training-card-tabs.mat-tab-group.mat-primary .mat-ink-bar {
  background-color: #000000 !important;
}

.training-card-tabs.mat-tab-group.mat-primary .mat-tab-header {
  border-bottom: none;
}

.training-card-tabs .mat-tab-body-wrapper {
  margin-top: 20px;
}

mark.marked-item {
  padding: .2em 0;
  border-radius: 0.2em;
  background: rgb(140 196 116 / 50%);
}

mark.marked-item.mark-highlighted {
  background: rgb(140 196 116 / 100%);
  animation: mark-highlighted-pulse 0.89s linear;
}

.mat-card.result-changed {
  & > .html-display-chunk {
      color: #15C72F;
      & > mark.marked-item {
          transition: none;
          color: currentColor;
          background: transparent;
      }
  }
}

.form-object-viewer .ui-tree-wrapper {
  width: 100%;
}

.form-object-viewer .ui-tree {
  display: flex;
}

.fr-view table {
  /* Overrides for hard coded width style attributes in documents */
  width: 100% !important;
}
.fr-view th, .fr-view thead {
  background: #ddd;
  /* Overrides for hard coded width style attributes in documents */
  width: auto !important;
}
.fr-view td, .fr-view th {
  padding: 6px;
  border: 1px solid #bbb;
  /* Overrides for hard coded width style attributes in documents */
  width: auto !important;
}

.document-view.fr-view table {
  width: 100% !important;
  margin: 16px 0;
}

.knowledge-document-editor {
  height: auto !important;
  ::marker {
    margin: 0 !important;
  }
  .fr-document {
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc;
  }
  .fr-box.fr-basic.fr-top .fr-wrapper {
    box-shadow: none;
  }
  .fr-toolbar {
    position: sticky;
    z-index: 2;
    top: 0;
    align-self: flex-start;
    border-top: none !important;
    &.fr-top {
        border-radius: 0 !important;
        box-shadow: 0 3px 0 rgba(0,0,0,0.06), 0 1px 0 rgba(0,0,0,0.12) !important;
    }
  }
  /* Enable animations on div wrappers for highlighting */
  [data-section-wrapper] {
    transition: box-shadow 0.7s ease;
  }
  .fr-popup {
    z-index: 90 !important;
  }
}

.document-save-controls {
  margin: 0 16px;
  .mat-form-field-wrapper {
      .mat-form-field-infix {
          border-top: none;
      }
  }
}

.document-section-highlighted {
  box-shadow: 0 0 0 4px #ffa500;
}

.document-input-controls {
  padding: 0 16px;
}

/* Lock these styles to the document diff id */
#document-diff {
  .line-added {
      background: #dfd !important;
      position: relative;
      padding-right: 60px;
      border: 1px solid #999;
      &::before {
        content: 'ADDED';
        font-size: 0.7rem;
        position: absolute;
        right: 3px;
        top: 2px;
        color: rgba(0,0,0,0.5);
        font-weight: 500;
        letter-spacing: 1px;
      }
  }
  
  .line-removed {
      background: #fee8e9 !important;
      position: relative;
      padding-right: 60px;
      border: 1px solid #999;
      &::before {
        content: 'REMOVED';
        font-size: 0.7rem;
        position: absolute;
        right: 3px;
        top: 2px;
        color: rgba(0,0,0,0.5);
        font-weight: 500;
        letter-spacing: 1px;
      }
  }
}

/* Try to fix on smaller screens the editor document looks like it's immediately cut off */
.fr-box.fr-basic .fr-element {
  min-height: 320px;
}

/* Styling for the saltchat component and hints. Have to do it here because eva-theme.css overrides shadow dom :( */
.question-input.mat-form-field-appearance-legacy {
  .mat-form-field-subscript-wrapper {
    font-size: 14px;
  }
  .mat-form-field-wrapper {
    padding-bottom: 1.6em;
  }
  .mat-hint {
    transition: ease-out;
    color: #666;
  }
}

/* Overwrite Froala Styling to fix janky css when we have columns showing when editing a document */
/* This helps on higher resolutions so the document doesn't spill into other elements */
@media (min-width: 992px) {
  .fr-box.fr-document {
    min-width: auto;
    max-width: 100%;
    width: 100%;
    .fr-wrapper .fr-element {
      max-width: 21cm;
      width: 100%;
    }
  }
  .fr-box.fr-document .fr-wrapper {
    min-width: auto;
    max-width: 100%;
    width: 100%;
  }
}

.mat-datepicker-input-container {
  width: 300px;
}

dynamic-material-form label {
  word-break: break-word;
}

.interaction-condition-builder .mat-tab-label {
  width: 200px;
}

.interaction-version-option .mat-option-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.entity-picker-dialog .mat-horizontal-stepper-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.entity-picker-dialog .mat-horizontal-stepper-header-container {
  height: 60px;
  padding-top: 12px;
}

/* Any styling to apply in a knowledge-section component innerHtml */
.knowledge-section-html {
  h1, h2, h3, h4, h5, h6 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  /* Prevent clicks happening on child elements of anchor tags where javascript is */
  /* executing logic for multi tabbing knowledge documents (LEGO-754) */
  a.disable-pointer-events > * {
    pointer-events: none;
  }
}

/* styling for child angular-material components in knowledge-multi-view */
.knowledge-multi-view-tabs {
  .mat-tab-header {
    border: 0;
  }
  .mat-ink-bar {
    background: transparent !important;
  }
  .mat-tab-list {
    padding: 6px 12px 0 12px;
  }
  .mat-tab-label {
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 6px 6px 0 0;
    height: 40px;
    &:hover {
      opacity: 1;
    }
    &.mat-tab-label-active {
      opacity: 1;
      background: #fff !important;
      border: 1px solid #ccc;
      border-bottom: none;
      box-shadow: 0 0 1px rgba(0, 0, 0, 0.4), 0 0 3px rgba(0, 0, 0, 0.15);
      color: var(--color-primary);
      cursor: default;
    }
    /* mat tab icons */
    .mat-icon-button {
      width: 24px;
      height: 24px;
      line-height: 24px;
    }
    .mat-icon-button .mat-icon {
      line-height: 17px;
    }
    .mat-icon {
      height: 18px;
      width: 18px;
    }
    .material-icons {
      font-size: 18px;
    }
  }
  .mat-tab-body-wrapper {
    height: 100%;
  }
}

.chat-full-messages {
  .chat-message-container {
    table {
      width: 100% !important;
      td {
        padding: 6px;
        border: 1px solid #ddd;
      }
    }
  }
}

.chat-knowledge-switch {
  &.mat-checked {
    .mat-slide-toggle-content {
      color: var(--color-primary-lighter);
    }
  }
  .mat-slide-toggle-content {
    color: #777;
    font-weight: 500;
  }
  .mat-slide-toggle-thumb {
    background-color: #999;
  }
  .mat-slide-toggle-bar {
    background-color: rgba(0, 0, 0, .18);
  }
  margin-left: 6px;
}

.processDashboard .ais-SearchBox-form input {
  padding: .6rem 1.7rem;
  padding-left: 25px;
  font-size: 14px;
}

.processDashboard .ais-HitsPerPage-select {
  font-size: 12px;
}

.processDashboard .ais-HitsPerPage {
  margin-left: 10px;
}

.processDashboard .ais-Pagination {
  margin: 10px 0;
}

.processDashboard .refreshButton .mat-button-wrapper {
  color: var(--color-primary);
}

.processDashboard .ag-root-wrapper-body {
  min-height: 300px;
}

.login-page .mat-horizontal-stepper-header-container {
  padding: 20px !important;
}

.mat-checkbox-label > span:last-child {
  white-space: pre-wrap;
}

.document-results-list {
  [class^=ais-] {
    font-size: 14px;
  }
  .ais-Highlight-highlighted, .ais-Snippet-highlighted {
    background-color: #ffdfab;
    border-radius: 2px;
    margin: 0 -2px;
    padding: 0 2px;
    overflow: hidden;
  }
  .ais-Hits {
    color: inherit;
  }
}

.group-interaction-creation-card {
  .mat-slide-toggle-content {
    padding-right: 1rem !important;
  }
}

.workflow-creation-card {
  .mat-slide-toggle-label {
    padding-top: 0 !important;
    margin-bottom: 0;
  }

  .mat-slide-toggle-content {
    padding-right: 1rem !important;
  }
}

.add-edit-release-notes {
  .mat-list-text {
    flex-direction: row !important;
    justify-content: space-between;
  }
}

.full-background {
  z-index: 0;
  padding: 0;
  width: calc(100% - 4.5rem);
  max-height: 100%;
  position: absolute;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow: auto;
  transition: var(--transition-speed);
}

.full-background-expanded {
  z-index: 0;
  padding: 0;
  width: calc(100% - 14.7rem);
  max-height: 100%;
  position: absolute;
  background-color: var(--color-lightest);
  height: 100%;
  display: flex;
  justify-content: center;
  overflow: auto;
  transition: var(--transition-speed);
}

.left-pane {
  .fa-times {
    color: var(--color-lightest);
  }
}

.multi-view-container {
  padding: 0;
  padding-top: 40px;
  /* max-width: 1300px; */
  width: 100%;
  height: 100%;
  overflow-y: auto;
  /* box-shadow: 0 0 3px 1px rgba(0,0,0,0.2); */

  .multi-view-component {
    > .mat-tab-group {

      > .mat-tab-body-wrapper {
        height: 100%;
        margin-top: 46px;
      }

      > mat-tab-header {
        position: fixed !important;
        z-index: 100;
        width: 100%;
        border: 0;

        .mat-tab-labels :last-child:not(:first-child).mat-tab-label {
          min-width: 0;
          padding: 0 12px;
          color: unset;
          opacity: unset;
        }

        .mat-ink-bar {
          background: transparent !important;
        }

        .mat-tab-list {
          padding: 6px 12px 0 12px;
        }

        .mat-tab-label {
          padding-left: 12px;
          padding-right: 12px;
          border-radius: 6px 6px 0 0;
          height: 40px;
          &:hover {
            opacity: 1;
          }
          &.mat-tab-label-active {
            opacity: 1;
            border-bottom: none;
            cursor: default;
          }
          /* mat tab icons */
          .mat-icon-button {
            width: 24px;
            height: 24px;
            line-height: 24px;
          }
          .mat-icon-button .mat-icon {
            line-height: 17px;
          }
          .mat-icon {
            height: 18px;
            width: 18px;
          }
          .material-icons {
            font-size: 18px;
          }
        }
      }
    }
  }
}

.component-container {
  padding: 1rem;
  width: 100%;
  height: 100%;
  background: var(--color-lightest);
}

.component-container-not-padded {
  width: 100%;
  height: 100%;
  background: var(--color-lightest);
}

.update-existing-change {
  .mat-dialog-container {
    padding: 40px !important;
  }
}

.learn-eva-videos {
  .mat-list-item {
    height: unset !important;
    padding: 10px 0 !important;
  }
}

.group-admin {
  .mat-list-item-content {
    padding: 0 !important;
  }
}

.notifications {
  .mat-list-item-content {
    padding: 16px 0 1px 0 !important;
  }

  .mat-list {
    padding-bottom: 20px;
    margin-bottom: 30px;
  }

  .text-line {
    font-size: 0.9rem;
    margin-bottom: 4px;
  }

  .notification-actions {
    margin-top: 10px;
  }
}

.search-table-wrapper {
  ais-instantsearch {
    width: 100%;
  }
}

/* .knowledge-document-editor {
  .fr-wrapper {
    background: var(--color-lightest) !important;
  }
} */

.machine-learning {
  .mat-tooltip {
    font-size: 15px;
    min-width: 100%;
  }
}


.full-background-spacing {
  margin-left: 4.5rem;
  height: 100%;
  width: calc(100% - 4.5rem);
  transition: var(--transition-speed);
}


.full-background-spacing-expanded {
  margin-left: 14.7rem;
  height: 100%;
  width: calc(100% - 14.7rem);
  transition: var(--transition-speed);
}

.full-screen {
  padding: 10px;
}

body::-webkit-scrollbar {
  width: 0.25rem;
}

body::-webkit-scrollbar-track {
  background: #1e1e24;
}

body::-webkit-scrollbar-thumb {
  background: #6649b8;
}

.navbar {
  position: fixed;
  display: flex;
  flex-direction: column;
  background-color: var(--color-primary);
  transition: width var(--transition-speed) ease;
  overflow: scroll;
  z-index: 99;
  border-radius: 0;
  border: 0;
}

.navbar-nav {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-items-container {
  .navbar-nav:first-child {
    flex: 1 0 0;
  }
}

dynamic-material-form-control > div {
  margin-bottom: 0.25rem;
}

dynamic-material-radio-group > mat-radio-group {
  .mat-radio-button + .mat-radio-button {
    margin-left: 1rem;
  }
}

dynamic-material-form-control label + dynamic-material-slide-toggle .mat-slide-toggle {
  margin-left: 0.5rem;
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  margin-top: 0.5rem;
}

.polaroid {
  .mat-form-field {
    width: 100% !important;
  }
}

.generic-search {
  position: relative;
  .token-expired-overlay {
    background: rgba(255, 255, 255, 0.85);
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
  }
  .token-expired-message {
    position: sticky;
    top: 0;
    padding-top: 2em;
  }
  .search-result {
    padding: 1.25rem 1.25rem 1.25rem 1.25rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1), 0 0px 2px rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
    position: relative;

    &.has-actions {
      padding-bottom: 4.5rem;
    }

    &:hover {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15), 0 0px 3px rgba(0, 0, 0, 0.3);
    }

    .search-result-tags {
      margin-bottom: 10px;
      padding-bottom: 5px;
      border-bottom: 1px solid #ddd;
      position: relative;
      min-height: 1px;
      width: 100%;
      & > span > span {
        font-size: 0.8rem;
        margin-bottom: 0.25rem;
        color: #555;
        margin-right: 0.25rem;
        display: inline-block;
        vertical-align: middle;
      }
    }

    .search-result-title {
      margin-top: 0;
      /* marked as important because some aloglia styling is conflicting and more specific */
      font-size: 1.2rem !important;
    }

    .search-result-subtitle {
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid var(--color-shade-300);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: var(--color-shade-600);
      font-size: 0.9rem;
    }

    .search-result-actions {
      margin-top: 0.5rem;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 0.5rem 1.25rem;
      border-bottom-left-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    }

    .document-section-content [class^=ais-] {
      font-size: 0.9rem;
    }

    .search-result-content {
      display: flex;
      /* marked as important because some aloglia styling is conflicting and more specific */
      font-size: 0.9rem !important;

      .search-result-content-property {
        width: 40%;
        padding-right: 30px;
        text-align: right;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .search-result-content-value {
        width: 60%;
        padding-left: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .search-result-content-value-full {
        width: 100%;
      }
    }
  }

  .even-div-tab {
    margin-left: 0 !important;
    margin-right: 15px !important;
  }

  .odd-div-tab {
    margin-right: 0 !important;
    margin-left: 15px !important;
  }

  .search-result-tab {
    width: 48% !important;
  }

  .search-result-padding-element-hide {
    display: none !important;
  }

  .div-mobile {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .search-result-mobile {
    width: 100% !important;
    min-width: auto !important;
  }

  .ais-SearchBox-form {
    height: 50px !important;

    .ais-SearchBox-input {
      height: 50px !important;
      background-color: var(--color-shade-100);
      color: var(--color-opposite);
      border: 1px solid var(--color-shade-300);
    }
  }

  .fa-angle-down {
    color: inherit;
  }
}

.nav-item {
  width: 100%;
}

.nav-link {
  display: flex;
  align-items: center;
  height: 4rem;
  cursor: pointer;
  color: var(--color-menu-item) !important;
  text-decoration: none;
  filter: grayscale(0%) opacity(0.9);
  transition: var(--transition-speed);

  &-badge {
    background: var(--color-secondary);
    font-size: var(--font-size-badge);
    width: 24px;
    height: 24px;
    border-radius: 12px;
    text-align: center;
    line-height: 24px;
    margin: 0;
  }
}

.nav-link:hover {
  filter: grayscale(0%) opacity(1);
  background: var(--color-primary-darker);
  color: var(--color-menu-item);
  text-decoration: none;
}

.nav-item-active {
  background: var(--color-primary-darker);
}

.link-text {
  display: none;
  margin-left: 0.5rem;
  text-shadow: none;
}

.nav-link svg, .app-reload {
  width: 2.5rem;
  min-width: 2rem;
  margin: 0 1.0rem;
}

/*
 - This is to fix some icons to align with the sizing
 - of other icons because in other icons the spacing
 - is in the SVG, which needs to be fixed.
 */
.nav-link svg.fix-width, .nav-link app-user-avatar {
  width: 2.1rem;
  min-width: 2rem;
  margin: 0 1.2rem;
}

.nav-link img {
  width: 2rem;
  min-width: 2rem;
  margin: 0 1.5rem;
  color: var(--color-menu-item);
}

.fa-primary {
  color: #ff7eee;
}

.fa-secondary {
  color: #B580D1;
}

.fa-primary,
.fa-secondary {
  transition: var(--transition-speed);
}

.logo {
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  color: var(--color-secondary);
  background: var(--color-darkest);
  font-size: 1.5rem;
  letter-spacing: 0.3ch;
  width: 100%;
  font-family: var(--font-heading);
  margin-bottom: 10px;
}

.logo svg, .app-reload {
  transform: rotate(0deg);
  transition: var(--transition-speed);
}

.logo-text
{
  display: inline;
  position: absolute;
  left: -999px;
  transition: var(--transition-speed);
  letter-spacing: -3px;
  font-size: 1.3em;
}

.Navigation_wOpportunityID {
  width: 1440px;
  height: 80px;
  object-fit: contain;
}

.mat-toolbar {
  height: 80px;
  object-fit: contain;
}

.eva_user {
  border-radius: 22px;
  background-color: white;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.11);
  background-color: #009CDE;
  color: white;
  font-weight: 500
}

.eva_userasked {
  color: #ffffff;
  font-weight: 100;
}

div[fxLayoutAlign] {
  padding: 10px 25px;
}

.mat-horizontal-stepper-header {
  height: 36px !important;
}

#introductionSection .mat-tab-header {
  border-bottom: none !important;
}

#reputationChangeSection .mat-tab-header {
  border-bottom: none !important;
  padding-left: 60px;
}

.full-width-input .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix {
  display: flex;
}

.workflow-picker .mat-dialog-container {
  padding: 0;
  background: #e5e5e5;
}

.document-table-of-contents-dialog .mat-dialog-container {
  padding: 0;
  background: #e5e5e5;
}


/* Small screens */
@media only screen and (max-width: 600px) {
  .navbar {
    bottom: 0;
    width: 100vw;
    height: 5rem;
    margin-bottom: 0px;
  }

  .logo {
    display: none;
  }

  .navbar-nav {
    flex-direction: row;
    display: flex;
  }

  .nav-link {
    justify-content: center;
  }

  main {
    margin: 0;
  }

  .cdk-overlay-pane {
    transform: none !important;
  }

  .mat-select-panel {
    min-width: 88% !important;
  }

  .nav-link-badge {
    top: -1.0rem;
    right: calc(50% - 1.5rem);
    // padding: 3px;
    position: absolute;
  }

  .full-background-spacing,
  .full-background-spacing-expanded {
    margin-left: 0;
    height: 100%;
    width: 100%;
  }

  .full-background,
  .full-background-expanded {
    margin-left: 0rem;
    width: 100%;
    margin-bottom: 5rem;
    max-height: calc(100vh - 5rem);
  }

  .component-container {
    padding: 30px;
    padding-top: 70px;
  }

  /* this hides the items in the view with the exception of the first three and the menu */
  .navbar-nav li:nth-child(n+5):not(:nth-last-child(-n+1)) {
    display: none;
  }

  /* give more room for the chat icon to sit in the app */
  .navbar-nav.minimized-full-chat li:nth-child(2) {
    padding: 0 40px 0 0;
    transition: var(--transition-speed);
  }
  .navbar-nav.minimized-full-chat li:nth-child(3) {
    padding: 0 0 0 40px;
    transition: var(--transition-speed);
  }

  .training-card {
    .mat-tab-body-content {
      width: 90%;
    }
  }

  .menu-items-container {
    flex-direction: row !important;
    overflow-y: hidden !important;
    overflow-x: auto !important;
    align-items: center;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    .navbar-nav:first-child {
      display: flex;
    }
  }

  .expand-button {
    display: none !important;
  }
}

#formScrnZoneVisualizer {
  .mat-form-field {
    width: 100% !important;
  }


  .mat-dialog-content{
    .mat-horizontal-stepper-header-container{
      padding: 10px !important;

      @media (max-width: 768px){
        padding: 20px 20px 0 20px !important;
      }

      .mat-horizontal-stepper-header{
        padding: 0;

        .mat-step-label{
          min-width: 0px !important;

          @media (max-width: 768px){
            display: none;
          }
        }
      }
      
      .mat-stepper-horizontal-line{
        margin: 5px -10px !important;

        @media (max-width: 768px){
          margin: 5px 0 0 0 !important;
        }
      }
    }
  }

  .mat-horizontal-stepper-header-container{
    padding-top: 20px !important;
    padding-bottom: 20px !important; 
  }

  .mat-form-field:not(.mat-form-field-type-mat-select){
    padding-bottom:12px;
    
    .mat-form-field-hint-wrapper{
      display: none;
      font-size: 12px;
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s 0.25s, opacity 0.25s linear;
    }

    &.mat-focused{
      /* padding-top: 15px;
      padding-bottom: 25px; */

      .mat-form-field-hint-wrapper{
        display: flex;
        font-size: 12px;
        visibility: visible;
        opacity: 1;
        transition: opacity 0.25s linear;
      }
    }
  }

  .current-form-step{
    display: none;
  }

  .tab-label {
    width: 100%; 
    height: 100%; 
    justify-content: center; 
    align-items: center; 
    display: flex;
  }

  .tab-label-text {
    white-space: pre; 
    word-wrap: none;
  }
}

.form-element-visualizer {
  .mat-form-field {
    width: 100% !important;
    margin-top: -10px;
  }

  .mat-form-field.mat-form-field-type-mat-select {
    margin-top: -5px;
  }

  .mat-slider {
    width: 100% !important;
  }


  .mat-menu-item.shrinkHeight {
    font-size: 14px !important;
    line-height: 24px !important;
    height: 24px !important;
  }

  .mat-icon-button.shrinkWidthHeight {
    padding: 0;
    min-width: 0;
    width: 14px;
    height: 21px;
    flex-shrink: 0;
    line-height: 21px;
  }
}

/* Large screens */
@media only screen and (min-width: 600px) {
  .navbar {
    top: 0;
    width: 4.5rem;
    height: 100vh;
    overflow: hidden;
  }

  .nav-link-badge {
    position: absolute;
    left: 2.5rem;
    top: 0;
  }
}



@media only screen and (max-width: 768px) {
  #formScrnZoneVisualizer {
    .current-form-step {
      display: flex;
      justify-content: center;
      padding: 10px;
      color: var(--color-primary);
      font-size: 20px;
    
      .mat-icon {
        margin-top: 1px;
        margin-right: 5px;
      }
    }
  }
}

.theme-icon {
  display: none;
}

.dark #darkIcon {
  display: block;
}

// css for minimized chat components
.eva-box-top-inline {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  background-color: var(--color-secondary);
  box-shadow: inset 0 -3px 3px -3px rgba(0,0,0,0.5);
  .eva-box-top-title {
    color: var(--color-menu-item);
    text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
    padding-left: 1rem;
    font-size: 1rem;
  }
  .mat-button {
    color: #fff;
  }
  .mat-icon-button i, .mat-icon-button .mat-icon {
    line-height: 36px;
  }
  .mat-icon {
    height: 36px;
    width: 36px;
    font-size: 36px;
    fill: #fff;
    color: #fff;
  }
}

.eva-box-top-inline-multiple-menu {
  position: relative;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  background-color: var(--color-secondary);
  span {
    color: var(--color-menu-item);
    padding: 0 0 0 .5rem;
  }
  button {
    color: black;
  }
  svg {
    z-index: 1;
    width: 30px;
    height: 30px;
    color: var(--color-lightest);
  }
}

.notifications-dialog {
  /* because this dialog sits on top of everything, manually space it with the navbar */
  width: 450px;
  margin: 50vh auto 3rem 4.25rem;
  height: 50vh;
  max-width: 552px;
}

.ag-root-wrapper {
  min-height: 300px;
}

.notifications-dialog .mat-dialog-container {
  background-color: var(--color-lightest);
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.31) 0px 0px 1px;
  padding-top: 0;
}

.notifications-dialog .mat-dialog-container .mat-dialog-content {
  max-height: inherit;
  overflow: visible;
}

//#region LaunchPad

.launchpad-dialog {
  /* because this dialog sits on top of everything, manually space it with the navbar */
  width: 380px;
  margin: 50vh auto 3rem 4.25rem;
  height: 50vh;
  max-width: 552px;
}

.launchpad-dialog .mat-dialog-container {
  background-color: var(--color-lightest);
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.31) 0px 0px 1px;
}

.launchpad-dialog .mat-dialog-container .mat-dialog-content {
  max-height: inherit;
}

.feedback-dialog .mat-dialog-container {
  background-color: var(--color-lightest);
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.31) 0px 0px 1px;
}

#launchpad {
  display: none;
  z-index: 101;
  padding: 0;
  margin-left: 5rem;
  width: calc(100% - 5rem);
  max-height: 100%;
  position: absolute;
  background-color: var(--color-lightest);
  height: 100%;

	&.shown {
		display: flex;
    flex-direction: column;

		&.start { 
      -webkit-animation: show 1000ms;
      animation: show 1000ms;
    }
    &.end { 
      animation: hide 1000ms; 
      -webkit-animation: hide 1000ms;
    }
    .content {
			// counteracts the blurred edges
			background: #310505;
			bottom: 0;
			left: 0;
			position: fixed;
			right: 0;
      top: 0;
      flex-wrap: wrap;
      scroll-behavior: smooth;
      overflow-y:scroll;
			
			nav {
				margin: 20px auto;
        width: 96%;
        display: grid;
        grid-template-columns: repeat( auto-fit, minmax(150px, 1fr) );
        grid-template-rows: auto;
        justify-items: center;
        align-items: center;
        column-gap: 15px;
        row-gap: 15px;

				a {
          color: var(--color-lightest);
          width: 100%;
          height: 100%;
					filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.4));
					font-size: 14px;
					text-align: center;
					&:active { 
						opacity: .6;	
          }

          padding: 0.3rem;
          svg {
            width: 100%;
            height: 80%;
            max-height: 150px;
            color: var(--color-lightest);
            display: block;
          }
          span {
            height: 20%;
            text-align: center;
            text-transform: uppercase;
            vertical-align: middle;
          }
				}
			}
		}
  }
}

@keyframes show {
	0% { opacity: 0; }
	100% { opacity: 1; }
}

@keyframes hide {
	0% { opacity: 1; }
	100% { opacity: 0; }
}

@keyframes mark-highlighted-pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(140, 196, 116, 1);
  }
  50% {
    box-shadow: 0 0 0 4px rgba(140, 196, 116, 1);
  }
  70% {
    box-shadow: 0 0 0 0 rgba(140, 196, 116, 1);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(140, 196, 116, 1);
  }
}

//#endregion LaunchPad

//#region Main loading classes
/**
 * This section contains the codes for the application in a loading state.
**/

.loading {
  opacity: 0;
  position: fixed;
  height: 100%;
  width: 100%;
  padding-top: 25vh;
  text-align: center;
  z-index: -1;
  transition: opacity .8s ease-out;
}

app-root:empty + .loading {
opacity: 1;
z-index: 100;
  h1 {
    font-family: "courier", monospace;
    color: var(--color-lightest);
    margin-bottom: 50px;
  }
}

//#endregion Main loading classes

.launch-pad-list {
  max-width: 100%;
  min-height: 60px;
  display: block;
  overflow: hidden;
}

.launch-pad-item {
  padding: 0 12px;
  margin-bottom: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: move;
  background: var(--color-light-gray);
  font-size: 16px;
  font-weight: 500;
  color: #333;
  border-radius: 6px;
  line-height: 40px;
}

.launch-pad-item:last-child {
  margin-bottom: 0;
}

.launch-pad-item .drag-handle {
  color: #999;
}

.launch-pad-item .button-pin-item:not(.pinned-item) {
  display: none;
}

.launch-pad-item:hover .button-pin-item {
  display: inline-block;
}

.form-builder .p-tree-wrapper {
  width: 100%;
}

//#region Unused Styles

//#endregion Unused Styles
.update-details-content-list {
  table, th, td {
    border: 1px solid #cccccc;
    border-collapse: collapse;
  }
}

/* Global Print styles for EVA */
.knowledge-print-show {
  display: none !important;
}

@media print {
  .app-top-nav {
    display: none;
  }
  /* -- BEGIN Print styles for knowledge documents -- */
  .document-header {
    & .document-group-name, & .document-actions {
      display: none;
    }
    border-bottom: 1px solid #aaa;
    padding: 0 !important;
    margin-bottom: 24px;
  }
  .document-name {
    font-size: 28px !important;
  }
  .document-table-of-contents {
    display: none;
  }
  .document-print-revision-date {
    display: block !important;
  }
  .document-body {
    & div > div:first-of-type {
      display:block;
    }
    & div > div:last-of-type {
      display:none;
    }
    padding: 0 !important;
    .mat-card {
      padding: 0 !important;
    }
    .app-knowledge-section {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
  .app-knowledge-section {
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;
    .section-link-button {
      display: none !important;
    }
  }

  .knowledge-print-show {
    display: block !important;
  }

  .knowledge-print-hide {
    display: none !important;
  }

  app-knowledge-section {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
/* -- END Print styles for knowledge documents -- */

.knowledge-menu-table-of-contents.mat-menu-panel,
.knowledge-menu-revisions.mat-menu-panel {
  max-width: 340px;
  max-height: calc(50vh - 48px);
  & .mat-menu-content {
    padding: 0;
  }
}

.ag-theme-material {
  @include ag-theme-material((
      // use theme parameters where possible
      material-primary-color: var(--color-primary),
      material-accent-color: var(--color-primary)
  ));

  .ag-header-cell-label {
    color: #000;
  }

  .ag-row {
    border-width: 0;
  }

  .ag-row:nth-child(even) {
    background-color: var(--color-light-gray);
  }
}

.mat-simple-snackbar span {
  word-break: break-word;
}

.generic-search-query-form-field {
  width: 100%;
  .mat-form-field-wrapper {
      margin: 0 !important;
      padding-bottom: 0 !important;
      // padding-bottom: 0;
      .mat-form-field-infix {
        padding: 0.75em 0 1em 0;
        border-top-width: 0.5em;
      }
  }
}